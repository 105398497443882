@media (min-width: 576px) {
  .container {
    max-width: inherit;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1680px;
  }
}

.name-logo {
  width: 240px;
  height: 60px;
  object-fit: contain;
}

.bo-footer {
  color: #EDE1F2;
  font-size: 12px;
}

html {
  font-size: 16px;
}

body {
  color: #000000;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h6.muted {
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

a {
  color: #350569;
  text-decoration: none;
}

a:hover {
  color: #350569;
  text-decoration: underline;
}

.btn-primary {
  background-color: #0066f9;
  border: 1px solid #005ce0;
}

.btn {
  border-radius: 0.1rem;
}

.bo-input {
  font-weight: 400;
  font-size: 16px;
  padding: 12px 6px;
  padding-left: 10px;
  background-color: #F0EDF3 !important;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #F0EDF3 !important;
  width: 100%;
  box-shadow: none !important;
}

.bo-input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bo-input-error {
  border: 1px solid #FF0000 !important;
}

.bo-eye-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #979797;
  width: 50px;
  height: 50px;
}

.bo-eye-wrapper:hover {
  color: gray;
}

.bo-validation-error {
  margin-top: 2px;
  font-weight: 400;
  font-size: 10px;
  color: red;
  text-align: left;
}

.bo-text {
  font-weight: 400;
  font-size: 16px;
}

.bo-text-small {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.bo-consent-text {
  font-weight: 400;
  font-size: 12px;
}

.bo-text-bold {
  font-weight: 600;
  font-size: 16px;
}

.bo-text-note {
  border-radius: 5px;
  color: rgba(23, 190, 187, 1);
  background-color: rgba(23, 190, 187, 0.1);
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.bo-larger {
  font-size: 18px;
  font-weight: 600;
}

.bo-larger-x2 {
  font-size: 21px;
  font-weight: 600;
}

.bo-larger-x3 {
  font-size: 24px;
  font-weight: 600;
}

.bo-text-red {
  color: red;
}

.bo-text-black {
  color: black;
}

.bo-link {
  color: #350569 !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 400;
  font-size: 16px;
}

.bo-link:hover {
  color: #9f9cac !important;
}

.bo-link-sm {
  font-size: 14px;
}

.bo-black-link {
  color: black !important;
  cursor: pointer;
}

.bo-black-link:hover {
  text-decoration: underline !important;
}

.bo-button-100 {
  width: 100px;
}

.bo-link:active {
  background-color: #e5e3ec;
  border-radius: 5px;
}

.bo-header {
  font-weight: 600;
  font-size: 24px;
}

.bo-button {
  outline: none !important;
  border: rgb(234, 230, 239) solid 2px !important;
  background-color: rgb(234, 230, 239) !important;
  border-radius: 500px;
  font-weight: 600;
  font-size: 18px;
  color: #350569 !important;
  padding: 10px;
}

.bo-button:hover {
  background-color: rgba(213, 206, 223) !important;
  border: rgb(213, 206, 223) solid 2px !important;
  color: #350569;
}

.bo-button:focus {
  background-color: rgba(53, 5, 105, 0.2) !important;
  box-shadow: none !important;
}

.bo-button:disabled {
  color: rgba(53, 5, 105, 0.75) !important;
}

.bo-button-outline {
  border: #350569 solid 2px !important;
  background-color: transparent !important;
  border-radius: 500px;
  font-weight: 600;
  font-size: 18px;
  color: #350569 !important;
  padding: 10px;
}

.bo-button-outline:hover {
  background-color: rgb(53, 5, 105) !important;
  color: white !important;
}

.bo-w-30 {
  width: 30px;
}

.bo-w-120 {
  width: 120px;
}

.bo-w-150 {
  width: 150px;
}

.bo-w-200 {
  width: 200px;
}

.bo-w-250 {
  width: 250px;
}

.bo-h-100 {
  height: 100px;
}

.bo-h-120 {
  height: 120px;
}

.bo-inline-badge {
  font-size: 12px
}

.bo-badge {
  font-size: 14px
}

.bo-pointer {
  cursor: pointer;
}

.bo-mobile {
  max-width: 440px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 !important;
}

.bo-rounded-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 32px;
  /* NOTE: white background removed */
  /* flex-grow: 1; */
}

.bo-code-container {
  display: block;
}

.bo-code-input {
  width: 44px;
  height: 50px;
  padding: 0;
  font-size: 20px;
  text-align: center;
  margin: 4px;
  text-transform: uppercase;
  color: #000000;
  border: 2px solid rgb(240, 237, 243);
  border-radius: 5px;
  background: rgb(240, 237, 243);
  background-clip: padding-box;
}

.bo-code-input:focus {
  appearance: none;
  outline: 0;
  border: 2px solid rgb(53, 5, 105);
}

.bo-language-switcher {
  position: absolute;
  top: 12px;
  right: 28px;
  z-index: 10;
}

.bo-language-switcher-dropdown {
  padding: 4px 0;
  width: 64px;
  background-color: transparent;
  border-color: white;
  color: white;
  border-style: solid;
  border-radius: 5px;
  border-width: 0.5px;
  cursor: pointer;
  outline: none !important;
}

.bo-language-switcher-options {
  padding: 4px 0;
  width: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  border-style: solid;
  background-color: white;
  border-color: white;
  border-width: 0.5px;
  border-radius: 5px;
}

.bo-language-switcher-option {
  padding: 0;
  width: 100%;
  border-width: 0;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: #350569;
  text-align: center;
}